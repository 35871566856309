.modalContent {
  padding: 0;
  position: relative;
  max-width: 550px;
}
.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 24px;
  z-index: 1;
}
.imgSec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: #f6f6f6;
  padding: 2rem;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;

  &__bar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 2rem;

    div {
      background: #fff;
      border-radius: 16px;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
      &.active {
        border-color: #366cbd;
      }

      img {
        width: 70%;
      }
    }
  }

  &__img {
    max-width: 250px;
    width: 80%;
  }
}

.txtSec {
  padding: 1rem 2rem 3rem;

  &__title {
    font-size: 16px;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  &__descrip {
    margin-bottom: 1rem;
    &__ttl {
      font-size: 13px;
      margin-bottom: 4px;
      font-weight: 600;
    }
    &__txt {
      font-weight: 300;
      font-size: 12px;
    }
  }

  &__price {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 1rem;
  }
}
.addOn {
  &__ttl {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 12px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;font-size: 11px;
  }
}
.agreement {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  input {
    border-color: #e7e7e7;
    width: 16px;
    height: 16px;
    accent-color: var(--primary-blue);
  }
  span {
    border: 1px solid #e7e7e7;
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  p {
    font-size: 11px;
  }

  &--error {
    span {
      border-color: red;
    }
    p {
      color: red;
    }
  }
}

@media screen and (max-width: 600px) {
  .close {
    top: 1rem;
    right: 1rem;
  }
  .imgSec {
    padding: 1rem;
    padding-left: calc(40px + 2rem);
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;

    &__bar {
      div {
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }
    }
  }
  .txtSec {
    padding: 1rem;
  }
}
