.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 1.5rem;
  width: 100%;
}
.label {
  font-size: 12px;
  background: transparent;
  padding: 0;
  margin-bottom: 12px;
  font-weight: 500;
}
.message {
  font-size: 12px;
  color: red;
  display: block;
  margin-top: 8px;
}
.input {
  height: 44px;
  padding-left: 1rem;
  border: 1px solid #1212121a;
  border-radius: 40px;
  background: transparent;
  font-size: 14px;
  padding-right: 10px;
  // color: var(--neutral-900);
  width: 100%;
  outline: none;
  // font-family: var(--font-mulish);

  &[type="radio"] {
    accent-color: var(--primary-blue);
  }

  &:disabled {
    cursor: not-allowed;
  }
}
.padded {
  padding-left: 3rem;
}
.input::placeholder,
.textarea::placeholder {
  opacity: 0.6;
}
.textarea {
  padding: 12px 1rem;
  border: 1px solid #94a3b8;
  border-radius: 8px;
  background: transparent;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
  height: 100px;
  outline: none;
  outline: none;
}
.error .input,
.error .textarea {
  border-color: red;
}
.req {
  color: red;
  font-weight: 600;
}
.length {
  text-align: right;
  font-size: 12px;
  color: #94a3b8;
}
.icon {
  position: absolute;
  top: 15px;
  left: 16px;

  svg {
    width: 14px;
  }
}
