.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 1.5rem;
  width: 100%;

  .input {
    width: 100%;
    padding: 10px;
    padding-left: 60px;
    // font-family: var(--font-mulish);
    // color: var(--neutral-900);
    border: 1px solid #1212121A !important;
    height: 44px;
    border-radius: 40px;

    &:focus {
      box-shadow: unset;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}
.label {
  font-size: 12px;
  background: transparent;
  padding: 0;
  margin-bottom: 12px;
}
.message {
  font-size: 12px;
  color: red;
  display: block;
  margin-top: 8px;
}
.error .input {
  border-color: red !important;
}
.req {
  color: red;
  font-weight: 600;
}

.hasValue {
  background: #fbfbff;
}
