.btn {
  border: none;
  outline: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 40px;

  &--blue-fill {
    color: var(--white);
    background: var(--primary-blue);
  }

  &__spinner {
    width: 16px;
  }

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn__spinner {
      margin-left: 10px;
    }
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
