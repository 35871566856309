.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 1.5rem;
  min-height: 100dvh;
}
.aside {
  background-color: #f7f9fc;
  border-radius: 20px;
  padding: 1rem;
  background-image: url(../../assets/images/greenBg.png);
  background-size: cover;
  background-position: center;
  display: flex;
}
.card {
  color: #fff;
  padding: 1.5rem;
  margin-top: auto;
  box-shadow: 0px 0.5px 12.02px -0.5px #0000002e;
  box-shadow: 0px 1.13px 3.38px 0px #ffffff40 inset;
  background: linear-gradient(
    142.96deg,
    rgba(255, 255, 255, 0.222) -3.54%,
    rgba(114, 114, 114, 0.252) 95.15%
  );
  backdrop-filter: blur(14px);
  border-radius: 2px;

  &__txt {
    margin-bottom: 2rem;
    font-size: 18px;
    line-height: 1.3;
  }
  &__user {
    display: flex;
    justify-content: space-between;
  }
  &__profile {
    &__name {
      font-size: 18px;
      margin-bottom: 12px;
    }

    &__role {
      font-size: 11px;
      margin-bottom: 4px;
      font-weight: 300;
    }

    &__other {
      font-size: 10px;
      font-weight: 300;
    }
  }
  &__rating {
    display: flex;
    gap: 8px;

    svg:last-of-type {
      path {
        fill: #bdbdbd;
      }
    }
  }
  &__arrows {
    display: flex;
    gap: 1.5rem;
    justify-content: flex-end;
    margin-top: 1rem;

    svg:last-of-type {
      transform: rotate(180deg);
    }
  }
}
.content {
  &__logo {
    width: 100px;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .card {
    &__txt {
      font-size: 14px;
    }
    &__profile {
      &__name {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .main {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  .aside {
    display: none;
  }
  .content__logo {
    margin-bottom: 1rem;
  }
}
