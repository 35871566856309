.ttl {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 6px;
}
.txt {
  color: #1e272f80;
  margin-bottom: 2rem;
  font-size: 15px;
  width: 90%;
}
.form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__halfInput {
    width: 48.5% !important;
  }

  &__inputWrap {
    margin-bottom: 2rem;
  }
  &__btn {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;

    svg {
      width: 18px;
    }
  }
}
.back {
  margin-bottom: 8px;
  margin-top: -8px;
  width: 17px;
}

.infoSec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
  gap: 2rem 1.5rem;
}
.info {
  display: flex;
  align-items: center;
  gap: 12px;
  text-transform: capitalize;

  svg {
    width: 40px;
  }

  &__txt1 {
    font-weight: 500;
    margin-bottom: 4px;
    font-size: 13px;
  }
  &__txt2 {
    color: #1e272f99;
    font-weight: 300;
    font-size: 10px;
  }
}
.bill {
  margin-bottom: 1.2rem;
  &__ttl {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 4px;
  }
  &__txt {
    color: #1e272f80;
    font-size: 13px;
  }
}
.solarRec {
  background: #f3f6ff;
  padding: 1rem;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 4rem;

  &__ttl {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 12px;
  }
  &__txt1 {
    font-size: 19px;
    font-weight: 600;
  }
  &__txt2 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  &__txt3 {
    font-weight: 300;
    font-size: 10px;
  }
}
@media screen and (max-width: 800px) {
  .ttl {
    font-size: 20px;
  }
  .txt {
    font-size: 14px;
    width: 100%;
  }
  .infoSec {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}