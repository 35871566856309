.modalDialog {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  align-items: center
}

.modalContent {
  border-radius: 16px;
  padding: 1.5rem;
  background:#fff;
  width: 90%;
  height: fit-content;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  max-height: 95vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
