.main {
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
}
.header {
  margin-bottom: 3rem;

  &__logo {
    display: block;
    width: 94px;
    margin-bottom: 2rem;
  }
  &__back {
    display: block;
    width: 17px;
    margin-bottom: 1rem;
  }
  &__ttl {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__txt {
    color: #1e272f80;
    text-align: center;
    margin: auto;
    font-size: 15px;
    max-width: 600px;
  }
}
.content {
  &__ctrl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    svg {
      width: 24px;
      path {
        fill: #000;
      }

      &:last-of-type {
        transform: rotate(180deg);
      }
    }
  }
}
.panels {
  display: flex;
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  margin-bottom: 4rem;
}
.recommended {
  margin-top: -26px;
  .panel__imgSec {
    border: 1px solid #366cbd;
    border-top-left-radius: 0;
  }

  &__txt {
    background: #366cbd;
    color: #fff;
    width: fit-content;
    padding: 4px 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
.panel {
  svg {
    width: 20px;
    display: block;
  }
  &__imgSec {
    background: #f6f6f6;
    border-radius: 16px;
    padding: 1.5rem;
    min-height: 240px;
    margin-bottom: 10px;

    img {
      width: 80%;
      height: auto;
      margin: auto;
      display: block;
    }
  }
  &__radio {
    border: 1px solid #1e272f80;
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
  &__name {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
    cursor: pointer;
  }
  &__price {
    font-weight: 500;
    font-size: 15px;
  }
}
.financing {
  max-width: 600px;
  margin: auto;

  &__options {
    border-bottom: 1px solid#E7E7E7;
    margin-bottom: 1.2rem;

    button {
      padding: 8px 1rem;
      background: transparent;
      border: none;
      color: #8e9397;
      margin-right: 1rem;
      font-size: 14px;
      font-weight: 500;

      &.active {
        color: #366cbd;
        border-bottom: 2px solid currentColor;
        margin-bottom: -2px;
      }
    }
  }
}
.address {
  line-height: 1.5;
  margin-bottom: 1.2rem;

  &__ttl {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  &__txt {
    font-size: 12px;
  }
}
.cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 13px;

  span {
    &:first-of-type {
      color: #1e272f80;
      font-weight: 500;
    }
    &:last-of-type {
      font-weight: 600;
    }
  }
}
.loan {
  &__ttl {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &__txt {
    font-size: 12px;
    color: #8e9397;
    margin-bottom: 1rem;
    font-weight: 400;
  }
}
.btn {
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 12px;
}
@media screen and (max-width: 800px) {
  .header {
    &__ttl {
      font-size: 20px;
    }
    &__txt {
      font-size: 14px;
      width: 100%;
    }
  }
  .panels {
    gap: 2rem 1rem;
  }
  .panel {
    &__imgSec {
      padding: 1rem;
    }
    &__name {
      font-size: 14px;
      margin-bottom: 6px;
    }
    &__price {
      font-size: 13px;
    }
  }
  .recommended {
    margin-top: 0;
  }
}
