@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-blue: #366cbd;
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  color: #1e272f;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

button,
input,
textarea {
  font-family: "Lexend Deca", sans-serif;
}

img,
svg {
  width: auto;
  height: auto;
}

/* Scrollbar */
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-blue);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-blue);
}

button,
[role="button"] {
  cursor: pointer;
}
