.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 1.5rem;
  width: 100%;
}
.label {
  font-size: 12px;
  background: transparent;
  padding: 0;
  margin-bottom: 12px;
  font-weight: 500;
}
.message {
  font-size: 12px;
  color: red;
  display: block;
  margin-top: 8px;
}
.input {
  height: 44px;
  padding-left: 1rem;
  border: 1px solid #1212121A;
  border-radius: 40px;
  background: transparent;
  font-size: 14px;
  padding-right: 10px;
  width: 100%;
  outline: none;
  display: grid;
  grid-template-columns: 1fr 20px;
  gap: 10px;
  align-items: center;

  input {
    border: none;
    background: transparent;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    width: 100%;

    &::placeholder {
      font-family: inherit;
      font-size: inherit;
      // font-weight: 300;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.disabled {
    cursor: not-allowed;
    // background: var(--bg-0);
    box-shadow: none;
  }
}

.error .input {
  border-color: red;
  box-shadow: none;
}
.req {
  color: red;
  font-weight: 600;
}

.optionsList {
  position: absolute;
  background: var(--white);
  top: 75px;
  width: 100%;
  border-radius: 4px;
  padding: 4px 0;
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 /10%);
  max-height: 220px;
  overflow: auto;
  z-index: 1;

  &__noOptions {
    text-align: center;
    padding: 8px;
    text-transform: capitalize;
  }
  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: transparent;
    border: none;
    text-align: left;

    &:hover {
      background: #e8f0ff;
    }
  }
}
.icon {
  position: absolute;
  top: 16px;
  left: 16px;

  svg {
    width: 14px;
  }
}
.padded {
  padding-left: 3rem;
}