.ttl {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 6px;
}
.txt {
  color: #1e272f80;
  margin-bottom: 2rem;
  font-size: 15px;
  width: 90%;
}
.form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__halfInput {
    width: 48.5% !important;
  }

  &__inputWrap {
    margin-bottom: 2rem;
  }
  &__btn {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;

    svg {
      width: 18px;
    }
  }
}

.agreement {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 3rem;
  cursor: pointer;

  input {
    border-color: #e7e7e7;
    width: 20px;
    height: 20px;
    accent-color: var(--primary-blue);
  }
  span {
    border: 1px solid #e7e7e7;
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  p {
    font-size: 11px;
  }

  &--error {
    span {
      border-color: red;
    }
    p {
      color: red;
    }
  }
}

@media screen and (max-width: 800px) {
  .ttl {
    font-size: 20px;
  }
  .txt {
    font-size: 14px;
    width: 100%;
  }
}
